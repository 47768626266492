import React, { Fragment, useState,useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Button } from 'reactstrap'
import {useSelector} from 'react-redux'
import sparechangeIcon from '../../assets/images/whiteSparechangeIcon.svg'
import pushkaIcon from '../../assets/images/pushkaFlameWhite.svg'
import Flex from '../common/Flex'
import logo from '../../assets/images/scacademyhorizontal_light.png'
import logoPushka from '../../assets/images/horizontal_white_transparent.png'
import { Link } from 'react-router-dom'

const HorizantalNav = () => {    
    const {pushka} = useSelector((store) => store.brand )
    const [showBanner, setShowBanner] = useState(false)
    const location = useLocation();
    
    useEffect(() => {
        if(location.pathname === '/academy' || location.pathname === '' ){
            setShowBanner(true)
        }else{
            setShowBanner(false)
        }
    }, [location])
    
    useEffect(() => {
        window.scrollTo(0, 0);
      }, [showBanner]);
    
    const redirectHome =() => {
        window.location =(pushka?'https://www.pushka.fund':'https://www.sparechangegiving.com')
    }
    const redirectHelp =() => {
        window.location= ('https://intercom.help/sparechange/en/')
    }
    return (
        <div className='bg-primary p-2'>
            <Flex className={'text-white'} align='center'>
                <div className='p-2'><img  style={{height:'35px'}} src={pushka?pushkaIcon:sparechangeIcon}/></div>
                <Link to="/academy" className='btn  btn-secondary btn-glass px-3 py-2 mr-auto'>{`${pushka?"Pushka":"Sparechange"} Academy`}</Link>
               
                <div className='p-2 d-md-block d-none'><Button  onClick={redirectHome} className='btn-glass px-3 py-2'>Home</Button></div>
                <div className='p-2 d-md-block d-none'><Button onClick={redirectHelp} className='btn-glass px-3 py-2'>Help</Button></div>
            </Flex>
            <div >
                {showBanner && 
        <div className='p-4'>
                <div className="text-center my-6">
                    <img className='img-fluid mb-3' style={{maxWidth:200}} src={pushka?logoPushka:logo} alt="BigCo Inc. logo"/>
                    {/* <h6 class="text-muted fs-1">OFFICIAL TRAINING</h6> */}
                    <h1 class="text-white fs-7">Fundraise with Round-Ups</h1>
                    <p class="text-white">A step-by-step guide to launch your {pushka?"Pushka":"sparechange"} campaign.</p>
                </div>
            </div>
                }
        </div>

        </div>
    )
}

export default HorizantalNav
