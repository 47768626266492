import React from 'react'
import {Helmet} from "react-helmet";
import ScrollToTop from '../../common/ScrollToTop'

const PushkaGetStarted = () => {
  return (
    <div>
      <ScrollToTop/>
      <Helmet>
        <title>Getting Started- Pushka Academy</title>
        <link rel="canonical" href="https://learn.pushka.fund/academy" />
      </Helmet>
      <h1>Getting Started</h1>
      <p>Hi! Welcome to the Pushka Academy.</p>
      <p>This course was designed for a single goal: to provide step-by-step instructions for getting trained in using Pushka.</p>
      <p>If you complete all the lessons, you'll raise more unrestricted donations and engage with your community at scale!</p>
      <p><b>All users are highly recommended to complete this training.</b></p>
      <img className='img-fluid mb-3 rounded border' src={'https://res.cloudinary.com/ddjxszwkw/image/upload/v1642537699/learning/pushka_welcome_video_coming_soon.png'} alt="BigCo Inc. logo"/>

      {/* <div className="embed-responsive embed-responsive-16by9">
        <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0" allowfullscreen></iframe>
      </div>
      <p>Watch time: _____ </p> */}
      <p>Ready to take the next step in your donor engagement with Pushka? Let's begin.</p>
      
      

      
    </div>
  )
}

export default PushkaGetStarted
