import React from 'react'
import { Button, Card } from 'reactstrap'
import ScrollToTop from '../../common/ScrollToTop'
import bulb from '../../../assets/images/idea.png'
import Flex from '../../common/Flex'
import {Helmet} from "react-helmet";

const PushkaEmailCampaign = () => {

  const clicked =() =>{
    //we will add the analytics here for the user clicking download
  }
  
  return (
    <div>
      <ScrollToTop/>
      <Helmet>
        <title>Email Campaign - Pushka Academy</title>
        <link rel="canonical" href="https://learn.pushka.fund/academy/email-campaign" />
      </Helmet>
      <h1>Launch your email campaign!</h1>
      <p>Now that you’ve generated some buzz with your soft launch, its time to design and send your email campaign to your community. </p>
      <h2>What makes an effective launch email campaign?</h2>
      <p>Watch this quick video from Robyn Stein, Professor of Non-Profit Fundraising at NYU | Wagner:</p>
      {/* <div className="embed-responsive embed-responsive-16by9">
        <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0" allowfullscreen></iframe>
      </div> */}
      {/* <p>Watch time: ____</p> */}
      <img className='img-fluid mb-3 rounded border' src={'https://res.cloudinary.com/ddjxszwkw/image/upload/v1641496425/learning/pushka_email_launch_coming_soon.png'} alt="BigCo Inc. logo"/>

      <p>The key is sending different types of emails to avoid being redundant and to make sure you are sending messages that people will actually want to open and read.</p>
      <p>To recap, an effective campaign has the following:</p>
      <Card className='bg-light border shadow-none p-2 mb-2'>
        <Flex align='center'>
          <img  className='mr-2'style={{height:'35px'}} src={bulb}/>
          <p className='mb-0'>Total of 5-6 emails, sent 1 email / week</p>
        </Flex> 
      </Card>
      <Card className='bg-light border shadow-none p-2 mb-2'>
        <Flex align='center'>
          <img  className='mr-2'style={{height:'35px'}} src={bulb}/>
          <p className='mb-0'>Choose different “authors” for each email, if possible.</p>
        </Flex> 
      </Card>
      <Card className='bg-light border shadow-none p-2 mb-2'>
        <Flex align='center'>
          <img  className='mr-2'style={{height:'35px'}} src={bulb}/>
          <p className='mb-0'>Every email includes a strong call-to-actions with your invite URL (either hyperlinked or a button)</p>
        </Flex> 
      </Card>
      <p>To help you create your email campaign, we put together some templates and example emails and other resources.</p>        
      <img className='img-fluid mb-3 rounded border' src={'https://res.cloudinary.com/ddjxszwkw/image/upload/v1641504232/learning/pushka_templates_emails.png'} alt="BigCo Inc. logo"/>

      <h2>Access email templates and campaign resources</h2>
      <p>Click below to get access to free campaign resources and templates</p>
      <a 
        href='https://docs.google.com/document/d/1tFDJKO1lRwB9l1xrngal05YazNWdjnHDmQvCRaprdK4/edit?usp=sharing' 
        target={'_blank'}
        className='mb-2 btn btn-secondary'
        onClick={clicked}
        >Access Free Resources / Templates </a>
      <h3>Resource highlights:</h3>
      <ul>
        <li>Email campaign structure and format that actually convert</li>
        <li>Examples of emails from Sparechange clients</li>
        <li>Brainstorming questions and topic ideas for your team to use as inspiration</li>
        <li>Famous quotes about donating small amounts to reference</li>
      </ul>

    </div>
  )
}

export default PushkaEmailCampaign
