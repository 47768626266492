import React from 'react'
import {Helmet} from "react-helmet";
import ScrollToTop from '../../common/ScrollToTop'

const PushkaSecurity = () => {
  return (
    <div>
      <ScrollToTop/>
      <Helmet>
        <title>Security - Pushka Academy</title>
        <link rel="canonical" href="https://learn.pushka.fund/academy/security" />
      </Helmet>
      <h1>Security</h1>
      <h2>We're committed to keeping your data safe and secure.</h2>
      <p>Your peace of mind is our highest priority. Pushka works to protect you and your donors’ information, prevent unauthorized account access, and notify you of unusual activity.</p>
      <h3>SSL Encryption</h3>
      <p>Our website and app are secured with 256-bit encryption.</p>
      <h3>Secure Credentials</h3>
      <p>Multi-factor authentication, automatic logouts, and ID verification help prevent unauthorized access. You banking credentials and credit card data are not stored on Sparechange servers.</p>
      <h3>Trusted Partners</h3>
      <p>Pushka uses Plaid and Stripe which are industry standard partners for financial technology. </p>
      <h3>Account Alerts</h3>
      <p>We will contact your donors about unusual account activity for protection against fraud</p>
    </div>
  )
}

export default PushkaSecurity
