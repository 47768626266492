import React from 'react'
import {useSelector} from 'react-redux'
import Flex from '../common/Flex'
import logo from '../../assets/images/scacademycolor.png'
import logoPushka from '../../assets/images/transparentcolor.png'

const Footer = () => {
    const {pushka} = useSelector((store) => store.brand )
    return (
        <Flex column align={'center'} className={'my-3 p-3'}>
            <Flex>
                <div className='p-3' ><a href={pushka?'https://www.pushka.fund':'https://www.sparechangegiving.com'} target="_blank">Home</a></div>
                <div className='p-3' ><a href="https://intercom.help/sparechange/en/" target="_blank">Help</a></div>
                <div className='p-3' ><a href="https://www.sparechangegiving.com/privacy" target="_blank">Privacy</a></div>
                <div className='p-3' ><a href="https://www.sparechangegiving.com/terms" target="_blank">Terms</a></div>
            </Flex>
            <div className='p-3'>Copyright {new Date().getFullYear()} &copy;  Spare Change, LLC. All Rights Reserved.</div>
            <img className='img-fluid mb-3' style={{maxWidth:120}} src={pushka?logoPushka:logo} alt="BigCo Inc. logo"/>

        </Flex>
        
    )
}

export default Footer
