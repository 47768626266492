import React from 'react'
import {Helmet} from "react-helmet";
import ScrollToTop from '../common/ScrollToTop'

const WeeklyDonations = () => {
  return (
    <div>
      <ScrollToTop/>
      <Helmet>
        <title>Weekly Donations - Sparechange Academy</title>
        <link rel="canonical" href="https://learn.sparechangegiving.com/academy/weekly-donation" />
      </Helmet>
      <h1>Weekly Donations</h1>
      <p>Tap, Swipe, and Insert. Every purchase on their active card(s) will trigger a round-up. These round-ups are automatic and do not require your donors to do anything at the register or checkout.</p>
      <p>Every week, your donors’ round-ups are grouped together and withdrawn from their bank account. Sparechange will send your donors a transactional email - with your branding - letting them know how much they’ve donated.</p>
      <h2>Every round-up is recorded in the donor dashboard</h2>
      <p>If your donors want to know more details about exactly which transaction rounded-up, they can audit each transaction in their <b>Donations</b> page inside their dashboard. </p>
      <img className='img-fluid mb-3 rounded border' style={{maxWidth:300}} src={'https://res.cloudinary.com/ddjxszwkw/image/upload/v1640626571/learning/donation-history.gif'} alt="BigCo Inc. logo"/>

    </div>
  )
}

export default WeeklyDonations
