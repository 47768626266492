import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom'
import { Row, Col, Container } from 'reactstrap';
import _ from 'lodash'
import NextStep from '../components/common/NextStep'
import { nextSteps } from '../routes';
import {pushkaNextSteps} from '../pushkaRoutes'
import { useSelector } from 'react-redux';

const ContentWithAsideLayout = ({ banner, aside, footer, isStickyAside, children }) => {
    const [nextStep, setNextStep] = useState(null)
    const {pushka} = useSelector((store) => store.brand )
    const location = useLocation()
    
    useEffect(() => {
      setNextStep(_.find(pushka?pushkaNextSteps:nextSteps,{'route':location?.pathname}))
  }, [location])
  return (
    <Container >
      {/* {banner} */}
      <Row  className='mt-md-5 mb-5 px-md-3 mt-3 px-2'>
        <Col xxl="3" xl="4" lg='4' className={classNames('px-md-4 px-0 mb-3', { 'mb-3': !isStickyAside })}>
          {isStickyAside ? <div className="sticky-top sticky-sidebar">{aside}</div> : aside}
        </Col>
        <Col xxl="9" xl="8" lg='8'  className={classNames('px-md-4 px-0 ', { 'mb-3': !isStickyAside })}>
          <div style={{maxWidth:550}}>
            {children}
          </div>
          {nextStep && <NextStep {...nextStep}/>}
        </Col>
      </Row>
      {footer}
    </Container>
  );
};

ContentWithAsideLayout.propTypes = {
  aside: PropTypes.element.isRequired,
  banner: PropTypes.element,
  footer: PropTypes.element,
  isStickyAside: PropTypes.bool,
  children: PropTypes.node
};

ContentWithAsideLayout.defaultProps = { isStickyAside: true };

export default ContentWithAsideLayout;
