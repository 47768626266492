import React from 'react'
import {Helmet} from "react-helmet";
import ScrollToTop from '../../common/ScrollToTop'

const PushkaTheBasics = () => {
  return (
    <div>
      <ScrollToTop/>
      <Helmet>
        <title>The Basics - Pushka Academy</title>
        <link rel="canonical" href="https://learn.pushka.fund/academy/the-basics" />
      </Helmet>
      <h1>Lesson 1: Learn the Basics of Pushka</h1>
      <p>Let's walk you through the core features of Pushka so you'll know your way around.</p>
      {/* <div className="embed-responsive embed-responsive-16by9">
        <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0" allowfullscreen></iframe>
      </div>
        <p>Watch time: ____</p>
        <h2>What You'll Learn in This Lesson</h2> */}
        <p>In this lesson, you will:</p>
        <ul>
          <li>Learn about the features in your <b>Dashboard</b></li>
          <li>Access your <b>Invite Page</b> and see what your campaign will look like</li>
          <li>Learn the best practices for <b>Customizing Your Campaign</b></li>
          <li>Learn about Pushka <b>Security</b> to trust your donor’s data is safe</li>
        </ul>
      
      

      
    </div>
  )
}

export default PushkaTheBasics
