
import { useLocation   } from 'react-router-dom';
import { useEffect } from 'react';
import {useSelector} from 'react-redux'

export default function usePageViews() {
    
  const {pushka} = useSelector((state) => state.brand)
    const location = useLocation()
    useEffect(() => {
        window.analytics.page(location.pathname,
          {pushka:pushka}
          )
    }, [location])
}