export const getStartedRoute = {
    name: "Getting Started",
    to: "/academy",
}
export const theBasicsRoutes = {
    name: "The Basics",
    to: "/academy/the-basics",
    exact:true,
    children: [
        {name:"Dashboard", to:'/academy/dashboard'},
        {name:"Invite Page", to:'/academy/invite-page' },
        {name:"Customizing your campaign", to:'/academy/customize' },
        {name:"Security", to:'/academy/security' },
        // {name:"Donor Report", to:'/donor-report' },
        // {name:"Payout", to:'/payout' }
    ]
}

export const theDonorExperienceRoutes = {
    name: "The Donor Experience",
    to: "/academy/donor-experience",
    
    children: [
        {name:"Signing up", to:'/academy/signing-up'},
        {name:"Donation Preferences", to:'/academy/donor-preferences'},
        {name:"Weekly Donation", to:'/academy/weekly-donation' },
        {name:"Tax Receipts", to:'/academy/taxes'},
    ]
}
export const launchYourCampaignRoutes = {
    name: "Launch Your Campaign",
    to: "/academy/launch-campaign",
    children: [
        {name:"Defining Success", to:'/academy/campaign-success' },
        {name:"Best Practices", to:'/academy/best-practices' },
        {name:"Soft Launch", to:'/academy/soft-launch' },
        {name:"Email Campaign", to:'/academy/email-campaign' },
        // {name:"Event Campaigns", to:'/event-campaign'},
        // {name:"Other Ideas", to:'/other-campaign-ideas' }
    ]
}


export const completionRoute = {
    name: "Training completion",
    to: "/academy/completion"
}

export const nextSteps = [
    {route:'/academy', to:'/academy/the-basics', name:'The Basics'},
    {route:'/academy/the-basics', to:'/academy/dashboard', name:'The dashboard'},
    {route:'/academy/dashboard', to:'/academy/invite-page', name:'Then Invite Page'},
    {route:'/academy/invite-page', to:'/academy/customize', name:'Customize your campaign'},
    {route:'/academy/customize', to:'/academy/security', name:'Security'},
    {route:'/academy/security', to:'/academy/donor-experience', name:'The Donor Experience'},
    {route:'/academy/donor-experience', to:'/academy/signing-up', name:'Signing Up'},
    {route:'/academy/signing-up', to:'/academy/donor-preferences', name:'Donation Preferences'},
    {route:'/academy/donor-preferences', to:'/academy/weekly-donation', name:'Weekly Donation'},
    {route:'/academy/weekly-donation', to:'/academy/taxes', name:'Tax Receipts'},
    {route:'/academy/taxes', to:'/academy/launch-campaign', name:'Launch Your Campaign'},
    {route:'/academy/launch-campaign', to:'/academy/campaign-success', name:'Defining Success'},
    {route:'/academy/campaign-success', to:'/academy/best-practices', name:'Best Practices'},
    {route:'/academy/best-practices', to:'/academy/soft-launch', name:'Soft Launch'},
    {route:'/academy/soft-launch', to:'/academy/email-campaign', name:'Email  Campaign'},    
    {route:'/academy/email-campaign', to:'/academy/completion', name:'Traning Completion'},
    // {route:'/event-campaign', to:'/other-campaign-ideas', name:'Other Ideas'},
    // {route:'/other-campaign-ideas', to:'/completion', name:'Traning Completion'},
]