import React from 'react'
import {Helmet} from "react-helmet";
import ScrollToTop from '../../common/ScrollToTop'

const PushkaDonorPreferences = () => {
  return (
    <div>
      <ScrollToTop/>
      <Helmet>
        <title>Donation Preferences - Pushka Academy</title>
        <link rel="canonical" href="https://learn.pushka.fund/academy/donor-preferences" />
      </Helmet>
      <h1>Donation Preferences</h1>
      <p>Pushka gives your donors full control of their roundups. Below is a screenshot of the <b>Account Settings</b> page of the donor dashboard.</p>
      <img className='img-fluid mb-3 rounded border' src={'https://res.cloudinary.com/ddjxszwkw/image/upload/v1640536528/learning/donor-preferences.png'} alt="BigCo Inc. logo"/>
      <p>Inside their dashboard, donors can:</p>        
      <ul>
        <li>Increase their giving by applying a <b>Multiplier</b> to their roundups</li>
        <li>Limit how much they donate by setting a <b>Daily Max</b></li>
        <li>Specify if they want to use <b>Whole Dollar Roundups</b> to donate a dollar if their purchase ends in “.00”</li>
        <li><b>Pause</b> their giving by deactivating their account</li>
        <li>Change their <b>password</b></li>
        <li>Change the <b>bank account</b> used for their weekly donations</li>
        </ul>
    </div>
  )
}

export default PushkaDonorPreferences
