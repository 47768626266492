import React from "react";
import {
  BrowserRouter as Router,
} from "react-router-dom";
import HorizantalNav from "./components/nav/HorizantalNav";
import ContentWithAsideLayout from "./layouts/ContentWithAsideLayout";
import './assets/scss/theme.scss'
import NavCard from "./components/nav/NavCard";
import Footer from "./components/nav/Footer";
import { Provider } from "react-redux";
import store from "./redux/store";
import Branding from "./multiTenancy/Branding";
import Content from "./components/content/Content";


export default function App() {

  return (
    <Router>
      <Provider store={store}>
        <Branding/>
      <div>
        <HorizantalNav/>
            <ContentWithAsideLayout aside={<NavCard/>} >
              <Content/>

            </ContentWithAsideLayout>
      </div>
      <Footer/>
      </Provider>
    </Router>
  );
}

