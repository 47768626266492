import {    Link  } from "react-router-dom";
import React, { Fragment, useState,useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import _ from 'lodash'
import Flex from "../common/Flex";
import classNames from 'classnames';
import NavIcon from "./NavIcon";


const NavItem = ({route}) => {
    const [showNested, setShowNested] = useState(false)
    const location = useLocation();
    
    useEffect(() => {
        if(location.pathname === route.to){
            setShowNested(true)
        }else if(_.find(route?.children,{"to":location.pathname})){
            setShowNested(true)
        }else{
            setShowNested(false)
        }
    }, [location])
    return (
        <Fragment>
            <li>
                <Flex className={classNames('p-1 item',{'active':route.to === location?.pathname})}>
                    <NavIcon icon={route.to === location?.pathname?"open":"closed"}/>
                    <Link to={route.to}>{route.name}</Link>
                </Flex>
            </li>
            {route.children && showNested &&
            <ol >
                {route.children.map(each => {
                    return(
                        <li key={each.to}>
                        <Flex className={classNames('p-1 item',{'active':each.to === location?.pathname})}>
                                <NavIcon/>
                                <Link  to={each.to} >{each.name}</Link>
                                </Flex>
                        </li>
                    )
                })}
            </ol>
            }
        </Fragment>
    )
}

export default NavItem
