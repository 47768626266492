import React,{useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {Helmet} from "react-helmet";
import ApplyBranding from './ApplyBranding'
import pushkaColors from './pushkaColors'

const Branding = () => {
    const {pushka} = useSelector((store) => store.brand )
    const domain = window.location.host;
    const dispatch = useDispatch()
    useEffect(() => {
        if(domain === 'learn.pushka.fund'){
            dispatch({type:"UPDATE_BRAND",payload:{pushka:true}})
        }
    }, [domain])

    return (
        <div>
            {pushka &&
            <Helmet>
                <meta name="Pushka Academy" content= "Welcome to the Pushka Academy. This course was designed for a single goal: providing step-by-step instructions for getting trained in using Pushka successfully." />
                <link rel="icon" href="https://pushka.fund/wp-content/uploads/2021/07/cropped-pushka_favicon2-32x32.png" sizes="32x32" ></link>
                <link rel="apple-touch-icon" href="https://pushka.fund/wp-content/uploads/2021/07/cropped-pushka_favicon2-180x180.png"></link>
                <link rel="icon" href="https://pushka.fund/wp-content/uploads/2021/07/cropped-pushka_favicon2-192x192.png" sizes="192x192"></link>
            </Helmet>
            }
        
            <ApplyBranding colors={pushka?pushkaColors:null}/>
        </div>
    )
}

export default Branding
