import React from 'react'
import {Helmet} from "react-helmet";
import ScrollToTop from '../../common/ScrollToTop'

const TaxReceipts = () => {
  return (
    <div>
      <ScrollToTop/>
      <Helmet>
        <title>Tax Receipts - Pushka Academy</title>
        <link rel="canonical" href="https://learn.pushka.fund/academy/taxes" />
      </Helmet>
      <h1>Year End Tax Receipts</h1>
      <p>At the end of each calendar year, you will receive a year end report. This report can be used to send your donors a receipt to confirm their charitable contributions.</p>
      <p>Here is an example of a year end report:</p>
      <img className='img-fluid mb-3 rounded' src={'https://res.cloudinary.com/ddjxszwkw/image/upload/v1640543800/learning/year-end-csv.png'} alt="BigCo Inc. logo"/>
      
      <h2>Want to integrate with Pushka with a CRM?</h2>
      <p>Reach out to our integration team to learn how we can help!</p>
      <a
        className='mb-2 btn btn-secondary'
        href='https://pushka.fund/contact/'
        target={'_blank'}
        >Learn more</a>
    </div>
  )
}

export default TaxReceipts
