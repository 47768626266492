import React from 'react'
import {Helmet} from "react-helmet";
import ScrollToTop from '../common/ScrollToTop'

const InvitePage = () => {
  return (
    <div>
      <ScrollToTop/>
      <Helmet>
        <title>Invite Page - Sparechange Academy</title>
        <link rel="canonical" href="https://learn.sparechangegiving.com/academy/invite-page" />
      </Helmet>
      <h1>The Invite Page</h1>
      <p>Inside your dashboard, you will see a section that say "Invite Link". This is the URL of your campaign.</p>
      <img className='img-fluid mb-3 rounded border' src={'https://res.cloudinary.com/ddjxszwkw/image/upload/v1640361508/learning/sparechange-invite-link.png'} alt="BigCo Inc. logo"/>
        <p>Your invite page is a responsive  web app with an amazing mobile experience. </p>
        
      <img className='img-fluid mb-3 rounded border' style={{maxWidth:300}} src={'https://res.cloudinary.com/ddjxszwkw/image/upload/v1640363251/learning/page-demo-mobile.gif'} alt="BigCo Inc. logo"/>

      <p>Right now, your invite page has placeholder content. In the next lesson, we will show you how to customize the invite page for your nonprofit's brand.</p>
    </div>
  )
}

export default InvitePage
