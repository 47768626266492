import React from 'react'
import {Helmet} from "react-helmet";
import ScrollToTop from '../../common/ScrollToTop'

const PushkaCompletion = () => {
  return (
    <div>
      <ScrollToTop/>
      <Helmet>
        <title>Training Completion - Pushka Academy</title>
        <link rel="canonical" href="https://learn.pushka.fund/academy/completion" />
      </Helmet>
      <h1>Pushka Academy completion</h1>
      <p>Congratulations, you’ve made it to the end of Pushka Academy.</p>
      <p>By now, you’ve thoughtfully walked through each of the sections and completed the action items.</p>
      <p>Hopefully, you feel far more confident using a Pushka campaign and are Rounding-Up your way to your fundraising goals.</p>
    
    </div>
  )
}

export default PushkaCompletion
