import React from 'react'
import { Card } from 'reactstrap'
import Flex from './Flex'
import { useHistory } from 'react-router-dom'

const NextStep = ({to,name}) => {
    const history=useHistory()
    const redirect = () => {
        history.push(to)
    }
    return (
        <Card className='border shadow-none p-3' onClick={redirect}>
            <Flex align='center'>
                <div>
                    <p className='muted mb-2'>Next Up</p>
                    <h4 className='mb-0'>{name}</h4>

                </div>
                <div className='ml-auto'>
                    {">"}
                </div>
            </Flex>

        </Card>
    )
}

export default NextStep
