import React from 'react'
import {  faChevronDown, faChevronRight, faCircle, faCircleNotch} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NavIcon = ({icon,active}) => {
    return (
        <div>
        <FontAwesomeIcon icon={icon==="open"?faChevronDown:icon==='closed'?faChevronRight:faCircle} size={'xs'} className=" mr-2" />
            
        </div>
    )
}

export default NavIcon
