import React from 'react'
import {Helmet} from "react-helmet";
import ScrollToTop from '../common/ScrollToTop'

const Dashboard = () => {
  return (
    <div>
      <ScrollToTop/>
      <Helmet>
        <title>The Dashboard - Sparechange Academy</title>
        <link rel="canonical" href="https://learn.sparechangegiving.com/academy/dashboard" />
      </Helmet>
      <h1>The Dashboard</h1>
      <p>Inside the app, you'll see a dashboard like this.</p>
      <img className='img-fluid mb-3 rounded border' src={'https://res.cloudinary.com/ddjxszwkw/image/upload/v1640361330/learning/sparechange-dashboard.png'} alt="BigCo Inc. logo"/>
        <p>Inside the Dashboard, you can:</p>
        <ul>
          <li>Access your <b>campaign invite link</b>. This invite url is what your development team will use to invite donors.</li>
          <li><b>Customize your campaign</b> to reflect your branding, messaging, and images.</li>
          <li>Monitor your donors with <b>real time reporting</b> so you can see every penny your donors give.</li>
          <li>Review your balance and, when you're ready, <b>request a payout</b>.</li>
        </ul>
    </div>
  )
}

export default Dashboard
