const initialState = {
    pushka: false,
};

const brandReducer = (state = initialState, action) => {
    const {type, payload} = action;
    switch(type){
        case "UPDATE_BRAND":
            return {
                ...state,
                ...payload
            }
        default:
            return state
    }
}

export default brandReducer