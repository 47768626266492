import React from 'react'
import {Helmet} from "react-helmet";
import ScrollToTop from '../../common/ScrollToTop'

const PushkaSigningUp = () => {
  return (
    <div>
      <ScrollToTop/>
      <Helmet>
        <title>Signing Up - Pushka Academy</title>
        <link rel="canonical" href="https://learn.pushka.fund/academy/signing-up" />
      </Helmet>
      <h1>The 3 step sign-up process</h1>
      <p>Your Pushka donor signup process has been optimized for conversion and mobile experience.</p>
      <p>The simple 3 step process is easy and takes less than 2 minutes to complete:</p>
      <ol>
        <li>Create Account</li>
        <li>Link Cards and Bank Accounts</li>
        <li>Set Donation Preferences.</li>
      </ol>
      <div className="embed-responsive embed-responsive-16by9">
        <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/VUwdAAdEvmo"  allowfullscreen="allowfullscreen"
        mozallowfullscreen="mozallowfullscreen" 
        msallowfullscreen="msallowfullscreen" 
        oallowfullscreen="oallowfullscreen" 
        webkitallowfullscreen="webkitallowfullscreen"></iframe>
      </div>
      <p>Watch time: 1:06 </p>
      <p><b>To complete the signup process, your donors will need:</b></p>
      <ul>
        <li>a personal email address</li>
        <li>their online banking credentials for their cards / bank accounts</li>
      </ul>
      <h2>What if you donor’s don’t finish the signup process?</h2>
      <p>Don’t worry! Pushka will send your donors a series of activation reminders to encourage them to finish signing up!</p>
    </div>
  )
}

export default PushkaSigningUp
