import { Card } from "reactstrap";
import React from 'react'
import { theBasicsRoutes, getStartedRoute,completionRoute,theDonorExperienceRoutes ,launchYourCampaignRoutes} from "../../routes";
import { pushkaTheBasicsRoutes, pushkaGetStartedRoute,pushkaCompletionRoute,pushkaTheDonorExperienceRoutes ,pushkaLaunchYourCampaignRoutes} from "../../pushkaRoutes";
import NavItem from "./NavItem";
import { useSelector } from "react-redux";

const NavCard = () => {
  const {pushka} = useSelector((store) => store.brand )

    return (
        <Card className='p-2 pt-3 shadow-none border learning-nav-card'>
        <nav>
          {!pushka &&
            <ol >
              <NavItem route={getStartedRoute}/>
              <NavItem route={theBasicsRoutes}/>
              <NavItem route={theDonorExperienceRoutes}/>
              <NavItem route={launchYourCampaignRoutes}/>
              <NavItem route={completionRoute}/>
             </ol>
          }
          {pushka &&
            <ol >
              <NavItem route={pushkaGetStartedRoute}/>
              <NavItem route={pushkaTheBasicsRoutes}/>
              <NavItem route={pushkaTheDonorExperienceRoutes}/>
              <NavItem route={pushkaLaunchYourCampaignRoutes}/>
              <NavItem route={pushkaCompletionRoute}/>
             </ol>
          }
           </nav>
           </Card>
    )
}

export default NavCard
