import React from 'react'
import {Helmet} from "react-helmet";
import ScrollToTop from '../common/ScrollToTop'


const LaunchCampaign = () => {
  return (
    <div>
      <ScrollToTop/>
      <Helmet>
        <title>Launch Your Campaign - Sparechange Academy</title>
        <link rel="canonical" href="https://learn.sparechangegiving.com/academy/launch-campaign" />
      </Helmet>
      <h1>Lesson 3: Ready, Set, Launch!</h1>
      <p>The time has come to launch your Round-Up and Donate campaign to your community! </p>
      <p>The Sparechange Academy is here is help make it easy and quick to launch by providing you toolkits, examples, and best practices so you can make the most of your campaign.</p>
      {/* <div className="embed-responsive embed-responsive-16by9">
        <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0" allowfullscreen></iframe>
      </div>
      <p>Watch time: _____ </p> */}
      <h2>What You'll Learn in This Lesson</h2>
      <p>In this lesson, you will:</p>
      <ul>
        <li>Learn how to <b>define success</b> for your campaign, and to set your <b>campaign goals</b></li>
        <li>Learn <b>best practices</b> for using your invite link</li>
        <li>Prepare for your <b>soft launch</b>, and invite your organizations leadership to round-up</li>
        <li>See examples of an <b>email marketing</b> launch campaign</li>
        <li>Learn how to make the ask during <b>in-person programing and events</b></li>
        <li>Get inspired from <b>other campaign ideas</b></li>
      </ul>
    </div>
  )
}

export default LaunchCampaign
