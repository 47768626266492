import React from 'react'
import {Helmet} from "react-helmet";
import ScrollToTop from '../../common/ScrollToTop'

const PushkaWeeklyDonations = () => {
  return (
    <div>
      <ScrollToTop/>
      <Helmet>
        <title>Weekly Donations - Pushka Academy</title>
        <link rel="canonical" href="https://learn.pushka.fund/academy/change-causes" />
      </Helmet>
      <h1>Change Causes</h1>
      {/* <p>Tap, Swipe, and Insert. Every purchases on their active cards will trigger a round-up. These round-ups are automatic and do not require your donors to do anything at the register or checkout.</p>
      <p>Every week, your donors’ round-ups are grouped together and withdrawn from their bank account. Pushka will send your donors a transactional email letting them know how much they’ve donated.</p>
      <h2>Every round-up is recorded in the donor dashboard</h2>
      <p>If your donors want to know more details about exactly which transaction rounded-up, they can audit each transaction in their <b>Donations</b> page inside their dashboard. </p>
      <img className='img-fluid mb-3 rounded border' style={{maxWidth:300}} src={'https://res.cloudinary.com/ddjxszwkw/image/upload/v1640626571/learning/donation-history.gif'} alt="BigCo Inc. logo"/> */}

    </div>
  )
}

export default PushkaWeeklyDonations
