import React from 'react'
import { Button, Card } from 'reactstrap'
import ScrollToTop from '../../common/ScrollToTop'
import bulb from '../../../assets/images/idea.png'
import Flex from '../../common/Flex'
import file from '../../../assets/files/pushka-softlaunch-toolkit.pdf'
import {Helmet} from "react-helmet";

const PushkaSoftLaunch = () => {
  const clicked =() =>{
    //we will add the analytics here for the user clicking download
  }
  return (
    <div>
      <ScrollToTop/>
      <Helmet>
        <title>Soft Launch - Pushka Academy</title>
        <link rel="canonical" href="https://learn.pushka.fund/academy/soft-launch" />
      </Helmet>
      <h1>Soft Launch with your Board and Lay Leaders</h1>
      <p>Now that you have designed your campaign and defined your goals, its time to invite donors to Round-Up!</p>
      <p>Based on our experience working with non-profits, we noticed one thing that distinguishes the most successful campaigns:</p>
      <Card className='bg-light border shadow-none p-2 mb-2'>
        <Flex align='center'>
          <img  className='mr-2'style={{height:'35px'}} src={bulb}/>
          <p className='mb-0'>Organizations that “soft-launch” with their board and lay leadership are 4x more likely to hit their fundraising goals.</p>
        </Flex> 
      </Card>
      <h2>You’re probably asking, what is a soft launch?</h2>
      <p>Watch this quick video from Robyn Stein, Professor of Non-Profit Fundraising at NYU | Wagner:</p>
      
      <img className='img-fluid mb-3 rounded border' src={'https://res.cloudinary.com/ddjxszwkw/image/upload/v1641496425/learning/pushka_softlaunch_coming_soon.png'} alt="BigCo Inc. logo"/>
      {/* <p>Watch time: ____</p> */}
      <h3>Who should you soft launch to? </h3>
      <p>This is an important step - identifying leaders and influencers in the community. We suggest answering these questions as a starting point:</p>
      <ul>
        <li>Who on the board is vocal about trying new things and new technology?</li>
        <li>Which staff members have strong relationships with the community? - examples of this are people in programing and development.</li>
        <li>Who in the community volunteers on a regular basis?</li>
      </ul>
      <p>Once you create a list, its time to launch. Check out our soft launch toolkit for more resources!</p>
        <img className='img-fluid mb-3 rounded border' src={'https://res.cloudinary.com/ddjxszwkw/image/upload/v1641504232/learning/pushka_download_softlaunch.png'} alt="BigCo Inc. logo"/>

      <h2>Download the Free PDF Soft-Launch toolkit</h2>
      <p>To make it easy to Soft Launch, use the Pushka Soft Launch Toolkit. Click below to download the free PDF.</p>
      <a className='mb-2 btn btn-secondary'onClick={clicked} href={file} download="pushka-softlaunch-toolkit.pdf">Download Free PDF Toolkit</a>

      <h4>Toolkit highlights</h4>
      <ul>
        <li>Download this thoughtful toolkit as a PDF to print and share with your team</li>
        <li>The toolkit outlines the 4 step soft-launch process</li>
        <li>Use the email, text message, and phone scripts and templates for your initial ask, follow ups, and thank yous.</li>
        <li>Reference the FAQ section to answer any donor questions</li>
      </ul>

    </div>
  )
}

export default PushkaSoftLaunch
