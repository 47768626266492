import React from 'react'
import {Helmet} from "react-helmet";
import ScrollToTop from '../common/ScrollToTop'

const DonorExperience = () => {
  return (
    <div>
      <ScrollToTop/>
      <Helmet>
        <title>The Donor Experience - Sparechange Academy</title>
        <link rel="canonical" href="https://learn.sparechangegiving.com/academy/donor-experience" />
      </Helmet>
      <h1>Lesson 2: The Donor Experience</h1>
      <p>Before launching your sparechange campaign, its helpful to understand the donor experience. </p>
      <p>Understanding how your donors interact with your sparechange campaign will improve your ability to answer questions from your donors, and feel confident to invite more people to round-up and donate.</p>
      {/* <div className="embed-responsive embed-responsive-16by9">
        <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0" allowfullscreen></iframe>
      </div> */}
      <h2>What You’ll Learn in This Lesson:</h2>
      <p>In this lesson, you will:</p>        
      <ul>
          <li>Learn how your donors will <b>activate their cards</b> for roundups</li>
          <li>Discover the <b>donation preferences</b> donors can modify</li>
          <li>Learn <b>how donations are transferred</b> and when your donors are notified</li>
          <li>Learn how to prepare for issuing <b>tax receipts</b> to your donors</li>
        </ul>
    </div>
  )
}

export default DonorExperience
