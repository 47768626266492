import React from 'react'
import { Card } from 'reactstrap'
import {Helmet} from "react-helmet";
import ScrollToTop from '../../common/ScrollToTop'

const PushkaDefiningSuccess = () => {
  return (
    <div>
      <ScrollToTop/>
      <Helmet>
        <title>Defining Success - Pushka Academy</title>
        <link rel="canonical" href="https://learn.pushka.fund/academy/campaign-success" />
      </Helmet>
      <h1>What does a successful Pushka campaign look like?</h1>
      <p>While every nonprofit is different, and fundraising and development often vary, Pushka's nonprofits typically have one of these three goals:</p>
      <ul>
        <li>Grow Predictable Unrestricted Donations</li>
        <li>Automate Community Engagement (at scale)</li>
        <li>Cultivate Future Donors and Leaders</li>
      </ul>
      {/* <h3>Predictable Unrestricted Donations</h3>
      <p>Nonprofits often run on tight budgets and lean on the generosity of individual donors to keep operations going. For these organizations, Sparechange is a source of recurring, predictable, unrestricted revenue.</p>
      <p>Here is an example:</p>
      <Card className='mb-2 p-2 border shadow-none ml-2 bg-light'>
        <i>A local church serving 180 families has a goal of raising $22,000 in annual unrestricted donations to cover 2 months of mortgage every year. To achieve this, the church needs to activate 60 donors.</i>
      </Card>
      <h3>Automate Community Engagement (at scale)</h3>
      <p>For many organizations, community impact is a priority. To some, this means growing an individual who benefits from programing into a supporter, at any capacity level. These organizations generally fundraise from institutional partners, larger donors, and grants. The challenge is, engaging community members takes a lot of work and the development team is busy focusing on grants and larger donors. For these organizations, their Sparechange campaign is an approachable ask for the community to transition from “I attend programing” to “I support this cause everyday”.</p>
      <p>Here is an example:</p>
      <Card className='mb-2 p-2 border shadow-none ml-2 bg-light'>
        <i>The community botanical garden with a $6M annual budget has a goal to engage with 2% of the community who visit the garden. With 100,000 visitors every year, they want to have 2,000 round-up donors giving back with every purchase!</i>
      </Card>
      <h3>Cultivate Future Donors and Leaders</h3>
      <p>It’s hard to fundraise from Millennials - there we said it! Generally, millennials do not think they have the capacity to give in a meaningful way. If nonprofits do not cultivate a relationship with this generation, they will miss out a massive fundraising potential. Millennials are projected to benefit from the largest generational transfer of wealth, ever! For legacy organizations, a Sparechange campaign is an investment into the future of the organization.</p>
      <p>Here is an example:</p>
      <Card className='mb-2 p-2 border shadow-none ml-2 bg-light'>
        <i>The 120 year old advocacy non-profit with a $100M annual budget has a goal to cultivate 5,000 young leaders every year. In 3-5 years, the non-profit plans to call on high potential young leaders and provide them with leadership opportunities.</i>
      </Card>
      <h2>How valuable is the average donor?</h2>
      <p>Donors typically generate $30 of round-up donations a month, which results in a $360 annual recurring gift! </p> */}
      <h2>Want help creating your campaign goals? </h2>
      <p>Reach out to the Pushka client success team to schedule a free meeting to discuss your campaign.</p>
    </div>
  )
}

export default PushkaDefiningSuccess
