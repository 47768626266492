import React from 'react'
import {Helmet} from "react-helmet";
import ScrollToTop from '../common/ScrollToTop'

const Customize = () => {
  return (
    <div>
      <ScrollToTop/>
      <Helmet>
        <title>Customize Your Campaign - Sparechange Academy</title>
        <link rel="canonical" href="https://learn.sparechangegiving.com/academy/customize" />
      </Helmet>
      <h1>Customize Your Campaign</h1>
      <p>Our easy to use customization takes less than 3 minutes to set up. This detailed lesson will walk your through the best practices.</p>
      {/* <div className="embed-responsive embed-responsive-16by9">
        <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0" allowfullscreen></iframe>
      </div>
        <p>Watch time: ____</p> */}
        <h3>Prepare the following:</h3>
        <ul>
          <li>Your icon</li>
          <li>Primary and secondary colors (HEX value format)</li>
          <li>Four (4) photos of your organization / community</li>
          <li>About your organization (2-3 sentences)</li>
          <li>Quote and headshot of a leader in your community</li>
          <li>Organization Mission</li>
        </ul>
        <h2>Your Brand (Logo and Colors)</h2>
        <p>By uploading your icon logo and colors, your campaign is transformed with your branding.</p>
        <div className='pl-md-2 pl-0'>
       <img className='img-fluid mb-3 rounded border' src={'https://res.cloudinary.com/ddjxszwkw/image/upload/v1640619744/learning/customize-brand.png'} alt="BigCo Inc. logo"/>
      </div> 
        <p>Some useful tips when uploading your colors and logo:</p>
        <ul>
          <li>Colors - Please provide your colors in HEX value format. Don’t know your HEX values? Here is a tool to help (<span><a href='https://image-color.com/' target={'_blank'}>https://image-color.com/</a></span>)</li>
          <li>Logo - Please upload a JPG or PNG. For best results, upload an icon without any text, square in size, and with a transparent background.</li>
        </ul>
        <h2>Show Your Community and Impact</h2>
        <p>Choose and upload 4 photos of your community and your impact. Emotional and personal photos will bring your campaign to life!</p>
        <p><b>Main Image:</b></p>
        <div className='pl-md-2 pl-0'>
       <img className='img-fluid mb-3 rounded border' src={'https://res.cloudinary.com/ddjxszwkw/image/upload/v1640620374/learning/main-image.png'} alt="BigCo Inc. logo"/>
      </div>     
      <p><b>About Images:</b></p>
      <div className='pl-md-2 pl-0'>
       <img className='img-fluid mb-3 rounded border' src={'https://res.cloudinary.com/ddjxszwkw/image/upload/v1640620451/learning/about-images.png'} alt="BigCo Inc. logo"/>
      </div> 
        <h2>Tell Your Story </h2>
        <p>Messaging is important and your sparechange campaign provides plenty of opportunity to tell your story. Customize your about section, add a quote from your leadership, and add your mission to the footer.</p>
        <div className='pl-md-2 pl-0'>
       <img className='img-fluid mb-3 rounded border' src={'https://res.cloudinary.com/ddjxszwkw/image/upload/v1640619744/learning/customize-brand.png'} alt="BigCo Inc. logo"/>
      </div> 

    </div>
  )
}

export default Customize
