import React from 'react'
import {Helmet} from "react-helmet";
import ScrollToTop from '../common/ScrollToTop'

const BestPractices = () => {
  return (
    <div>
      <ScrollToTop/>
      <Helmet>
        <title>Best Practices - Sparechange Academy</title>
        <link rel="canonical" href="https://learn.sparechangegiving.com/academy/best-practices" />
      </Helmet>
      <h1>Invite URL Best Practices</h1>
      <p>As you prepare to send traffic to your campaign, we want to share some best practices for sending your invite URL. Long URLs are often intimidating to click, so using these best practices will improve your campaign’s conversion rate.</p>
      <p>In this lesson, you will:</p>
      <ul>
        <li>Learn about <b>hyperlinking and button call-to-actions</b> for emails</li>
        <li>Learn about using <b>QR codes</b> for in person events</li>
        <li>Learn how to <b>subdomain redirect</b> to your campaign page (advanced)</li>
      </ul>
      <h2>Sending your invite URL over email</h2>
      <p><b>Call-To-Actions:</b></p>
      <p>A call-to-action is a prompt that tells the user to take some specified action. Some call to action examples are</p>
      <ul>
        <li>Round-Up and Donate!</li>
        <li>Join our round-up campaign!</li>
      </ul>
      <p><b>Hyperlinking:</b></p>
      <p>Hyperlinking allows you to embed your invite URL into you call-to-action in a regular email body. Here is what it looks like:</p>
      <div className='pl-md-2 pl-0'>
       <img className='img-fluid mb-3 rounded border ' src={'https://res.cloudinary.com/ddjxszwkw/image/upload/v1640554613/learning/hyperlink-example.png'} alt="BigCo Inc. logo"/>
      </div> 
      <p>To hyperlink your call to action, highlight it with your curser and press <b>Ctrl + K</b> (Windows) or <b>Command + K</b> (Mac) to open the hyperlink options. Enter your invite URL into URL destination and you’re all set.</p>
      <p><b>Buttons:</b></p>
      <p>If you use email templates, they often give you the option to add a button. </p>
      <p>Set the URL destination of the button to your invite URL and the text of the button to your call-to-action. Here is how it looks:</p>
      <div className='pl-md-2 pl-0'>
        <img className='img-fluid mb-3 rounded border' src={'https://res.cloudinary.com/ddjxszwkw/image/upload/v1640554995/learning/button-example.png'} alt="BigCo Inc. logo"/>
      </div> 
      <h2>Using QR codes for in person asks</h2>
      <p>QR codes — or "Quick Response" codes — are square-shaped barcodes that can be scanned by mobile devices. Many non-profits use QR code on flyers and signs for in-person programing and events. Community members are encouraged to scan the QR code with their mobile phone. Once scanned, the donor is directed to the campaign page, and they can signup on their phone!</p>
      <div className='pl-md-2 pl-0'>
       <img className='img-fluid mb-3 rounded border' src={'https://res.cloudinary.com/ddjxszwkw/image/upload/v1640555437/learning/qr-code-example.png'} alt="BigCo Inc. logo"/>
      </div> 
      <p>You can create a free QR Code at the <span><a href='https://www.qr-code-generator.com' target={'_blank'}>QR Code Generator</a > website</span> (<span><a href='https://www.qr-code-generator.com' target={'_blank'}>www.qr-code-generator.com</a ></span>)</p>
      <h2>Advanced: subdomain redirect</h2>
      <p>Want to make a verbal ask easier? Some Sparechange clients create a subdomain and redirect it to their campaign page. For example, let’s say your organizations main website is <b>www.website.com</b>. With a subdomain redirect, you can have the domain <b>roundup.website.com</b> redirect your donors directly to you domain name.</p>
      <p>With a subdomain redirect, your word of mouth ask change from “I’ll send you a link where you can sign up” to “visit roundup.website.com to sign up”.</p>
      <p>To create a subdomain redirect you will need access to create a new DNS record. Create a CNAME record for your subdomain (i.e. “roundup”) and set the destination to your invite URL.</p>
  </div>
  )
}

export default BestPractices
