import React from 'react'
import ScrollToTop from '../../common/ScrollToTop'
import {Helmet} from "react-helmet";

const PushkaInvitePage = () => {
  return (
    <div>
      <ScrollToTop/>
      <Helmet>
        <title>Invite Page - Pushka Academy</title>
        <link rel="canonical" href="https://learn.pushka.fund/academy/invite-page" />
      </Helmet>
      <h1>The Invite Page</h1>
      <p>Inside your dashboard, you will see a section that say "Invite Link". This is the URL of your campaign.</p>
      <img className='img-fluid mb-3 rounded border' src={'https://res.cloudinary.com/ddjxszwkw/image/upload/v1640361508/learning/pushka-invite-link.png'} alt="BigCo Inc. logo"/>
        <p>Your invite page is a responsive  web app with an amazing mobile experience. </p>
        
      <img className='img-fluid mb-3 rounded border' style={{maxWidth:300}} src={'https://res.cloudinary.com/ddjxszwkw/image/upload/v1640363251/learning/page-demo-mobile.gif'} alt="BigCo Inc. logo"/>

      <p>Right now, your invite page has placeholder content. In the next lesson, we will show you how to customize the invite page for your nonprofit's brand.</p>
    </div>
  )
}

export default PushkaInvitePage
