import React, { Fragment } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import BestPractices from './BestPractices'
import Customize from './Customize'
import Dashboard from './Dashboard'
import DefiningSuccess from './DefiningSuccess'
import DonorExperience from './DonorExperience'
import DonorPreferences from './DonorPreferences'
import GetStarted from './GetStarted'
import InvitePage from './InvitePage'
import LaunchCampaign from './LaunchCampaign'
import Security from './Security'
import SigningUp from './SigningUp'
import TaxReceipts from './TaxReceipts'
import TheBasics from './TheBasics'
import {useSelector} from 'react-redux'
import WeeklyDonations from './WeeklyDonations'
import PushkaGetStarted from './pushka/PushkaGetStarted'
import PushkaTheBasics from './pushka/PushkaTheBasics'
import PushkaDashboard from './pushka/PushkaDashboard'
import PushkaInvitePage from './pushka/PushkaInvitePage'
import SoftLaunch from './SoftLaunch'
import EmailCampaign from './EmailCampaign'
import Completion from './Completion'
import usePageViews from '../../hooks/usePageViews'
import PushkaCustomize from './pushka/PushkaCustomize'
import PushkaSecurity from './pushka/PushkaSecurity'
import PushkaDonorExperience from './pushka/PushkaDonorExperience'
import PushkaSigningUp from './pushka/PushkaSigningUp'
import PushkaDonorPreferences from './pushka/PushkaDonorPreferences'
import PushkaWeeklyDonations from './pushka/PushkaWeeklyDonations'
import PushkaChangeCauses from './pushka/PushkaChangeCauses'
import PushkaTaxReceipts from './pushka/PushkaTaxReceipts'
import PushkaLaunchCampaign from './pushka/PushkaLaunchCampaign'
import PushkaDefiningSuccess from './pushka/PushkaDefiningSuccess'
import PushkaBestPractices from './pushka/PushkaBestPractices'
import PushkaSoftLaunch from './pushka/PushkaSoftLaunch'
import PushkaEmailCampaign from './pushka/PushkaEmailCampaign'
import PushkaCompletion from './pushka/PushkaCompletion'


const Content = () => {
    usePageViews();

    const {pushka} = useSelector((store) => store.brand )

    return (
        <Switch>
            {!pushka && 
            <Fragment>
                <Route exact path="/"><Redirect to="/academy" /></Route>
                <Route path="/academy" exact><GetStarted /></Route>
                <Route path="/academy/the-basics"><TheBasics/></Route>
                <Route path="/academy/dashboard"><Dashboard/></Route>
                <Route path="/academy/invite-page"><InvitePage/></Route>
                <Route path="/academy/customize"><Customize/></Route>
                <Route path="/academy/security"><Security/></Route>
                <Route path="/academy/donor-experience"><DonorExperience/></Route>
                <Route path="/academy/signing-up"><SigningUp/></Route>
                <Route path="/academy/donor-preferences"><DonorPreferences/></Route>
                <Route path="/academy/weekly-donation"><WeeklyDonations/></Route>
                <Route path="/academy/taxes"><TaxReceipts/></Route>
                <Route path="/academy/launch-campaign"><LaunchCampaign/></Route>
                <Route path="/academy/campaign-success"><DefiningSuccess/></Route>
                <Route path="/academy/best-practices"><BestPractices/></Route>
                <Route path="/academy/soft-launch"><SoftLaunch/></Route>
                <Route path="/academy/email-campaign"><EmailCampaign/></Route>
                <Route path="/academy/completion"><Completion/></Route>
            </Fragment>
            }
            {pushka && 
            <Fragment>
                <Route exact path="/"><Redirect to="/academy" /></Route>
                <Route path="/academy" exact><PushkaGetStarted /></Route>
                <Route path="/academy/the-basics"><PushkaTheBasics/></Route>
                <Route path="/academy/dashboard"><PushkaDashboard/></Route>
                <Route path="/academy/invite-page"><PushkaInvitePage/></Route>
                <Route path="/academy/customize"><PushkaCustomize/></Route>
                <Route path="/academy/security"><PushkaSecurity/></Route>
                <Route path="/academy/donor-experience"><PushkaDonorExperience/></Route>
                <Route path="/academy/signing-up"><PushkaSigningUp/></Route>
                <Route path="/academy/donor-preferences"><PushkaDonorPreferences/></Route>
                <Route path="/academy/change-causes"><PushkaChangeCauses/></Route>
                <Route path="/academy/weekly-donation"><PushkaWeeklyDonations/></Route>
                <Route path="/academy/taxes"><PushkaTaxReceipts/></Route>
                <Route path="/academy/launch-campaign"><PushkaLaunchCampaign/></Route>
                <Route path="/academy/campaign-success"><PushkaDefiningSuccess/></Route>
                <Route path="/academy/best-practices"><PushkaBestPractices/></Route>
                <Route path="/academy/soft-launch"><PushkaSoftLaunch/></Route>
                <Route path="/academy/email-campaign"><PushkaEmailCampaign/></Route>
                <Route path="/academy/completion"><PushkaCompletion/></Route>
            </Fragment>
            }
        </Switch>
    )
}

export default Content
